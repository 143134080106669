<template>
  <b-tr>
    <b-td v-if="!smallTable">
      <div class="ms-1">
        <badge
          :status="feedbackUser.status"
          :statuses="statuses"
        />
      </div>
    </b-td>
    <b-td class="white-space-nowrap">
      {{feedbackUser.info.feedbackName}}
    </b-td>
    <b-td class="white-space-nowrap">
      <div>
        <strong>Richiesta:</strong>
        <br>
        {{userCreatedAt}}
      </div>
      <div class="mt-1">
        <strong>Ultima modifica:</strong>
        <br>
        {{userLastModified}}
      </div>
    </b-td>
    <b-td>
      <div>
        <strong>{{feedbackUser.info.labelFeedbackType}}</strong>
        <br>
        {{feedbackUser.info.labelFeedbackQuestion}}
        <b-button
          variant="link"
          @click="openModalNote(feedbackUser)"
          size="sm"
          class="text-info px-1 py-0 v-align-reset"
          v-if="!userGeneratedNote && this.feedbackUser.note"
          v-b-tooltip.hover
          title="Note"
        >
          <font-awesome-icon icon="info-circle" />
        </b-button>
      </div>
    </b-td>
    <b-td class="white-space-nowrap" v-if="!smallTable">
      <div>
        <font-awesome-icon :icon="userFeedbackTypeIcon" class="me-2 fa-fw"/>
        <span>{{userFeedbackTypeDesc}}</span>
      </div>
      <div class="mt-1" v-if="userGeneratedNote">
        <b-button
          variant="info"
          @click="openModalNote(feedbackUser)"
          size="xs"
        >
          Note
        </b-button>
      </div>
    </b-td>
    <b-td class="text-center">
      {{feedbackUser.totalResponses}}
    </b-td>
    <b-td class="white-space-nowrap table-cell--compact text-right" v-if="!smallTable">
      <b-button
        v-if="closeEnable"
        variant="danger"
        @click="closeFeedback"
        size="sm"
        v-b-tooltip.hover
        title="Chiudi feedback"
      >
        <font-awesome-icon icon="times-circle"/>
      </b-button>
      <b-button
        v-if="!isClosed"
        variant="warning"
        v-clipboard="badgeUriToShare"
        @success="handleSuccess"
        @error="handleError"
        size="sm"
        class="ms-2"
        v-b-tooltip.hover
        title="Copia link"
      >
        <font-awesome-icon icon="copy"/>
      </b-button>
      <b-button
        v-if="!isClosed"
        variant="warning"
        @click="openModalShare"
        size="sm"
        class="ms-2"
        v-b-tooltip.hover
        title="Condividi"
      >
        <font-awesome-icon icon="share-alt"/>
      </b-button>
    </b-td>
    <modal-badge-share
      :id="feedbackUserBadge"
      :uri="badgeUriToShare"
      modalTitle="Invia richiesta feedback a"
      :title="shareTitle"
      :description="shareDescription"
    />
  </b-tr>
</template>
<script>
import { formatDateTime } from '@/utils/formatterHelper';
import { createBadgeCompleteUrl, createBadgeUri } from '@/utils/utilities';
import { toastInformation } from '@/utils/toast';

const Badge = () => import('@/components/helpers/Badge.vue');
const ModalBadgeShare = () => import('@/components/userSections/ModalBadgeShare.vue');

export default {
  name: 'FeedbackUserInfo',
  components: { ModalBadgeShare, Badge },
  props: {
    feedbackUser: {
      status: String,
      createdAt: Date,
      lastModified: Date,
      info: {
        feedbackName: String,
        feedbackType: String,
        feedbackQuestion: String,
        labelFeedbackType: String,
        labelFeedbackQuestion: String,
      },
      singleResponse: Boolean,
      totalResponses: Number,
    },
    // openModalShare: Function,
    openModalNote: Function,
    smallTable: Boolean,
    statuses: String,
  },
  computed: {
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
    shareTitle() {
      return `${this.currentName} ti ha richiesto di inviare un feedback`;
    },
    shareDescription() {
      return `Questo è il link da usare per fornire un feedback su ${this.currentName}`;
    },
    userCreatedAt() {
      return formatDateTime(this.feedbackUser.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.feedbackUser.lastModified);
    },
    userFeedbackTypeIcon() {
      if (this.feedbackUser.fromFeedbackUser) {
        return 'user-cog';
      }
      if (this.feedbackUser.singleResponse) return 'user';
      return 'users';
    },
    userFeedbackTypeDesc() {
      if (this.feedbackUser.fromFeedbackUser) {
        return 'Generato';
      }
      if (this.feedbackUser.singleResponse) return 'Singolo';
      return 'Multiplo';
    },
    badgeUri() {
      return createBadgeUri(this.feedbackUser.info.feedbackName);
    },
    badgeUriToShare() {
      return createBadgeCompleteUrl(this.feedbackUser.info.feedbackName);
    },
    feedbackUserNoteId() {
      return `${this.feedbackUser.info.feedbackName}-note`;
    },
    feedbackUserBadge() {
      return `${this.feedbackUser.info.feedbackName}-badge`;
    },
    closeEnable() {
      return (!this.feedbackUser.singleResponse && this.feedbackUser.status === 'OPEN');
    },
    isClosed() {
      return this.feedbackUser.status === 'CLOSE';
    },
    userGeneratedNote() {
      return !!(this.feedbackUser.note && this.feedbackUser.fromFeedbackUser);
    },
  },
  methods: {
    handleSuccess(e) {
      console.log('copy data', e);
      toastInformation('Link badge copiato nella clipboard');
    },
    handleError(e) {
      console.log('copy data error', e);
    },
    openModalShare() {
      this.$bvModal.show(this.feedbackUserBadge);
    },
    openModalNotes() {
      this.$bvModal.show(this.feedbackUserNoteId);
    },
    closeFeedback() {
      this.$store.dispatch('score/closeFeedbackUser', this.feedbackUser.info.feedbackName);
    },
  },
};
</script>

<style scoped>

</style>
